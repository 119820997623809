import { LandingProgramNavigationProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Module<LandingProgramNavigationProps> = {
	schemaType: "module",
	component: "LandingProgramNavigation",
	category: "content",
	displayName: "LandingProgramNavigation",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentArray",
					title: "Tabs",
					key: "programTabs",
					whiteList: ["LandingProgramNavigationTab"],
					contentType: "components",
				},
				{
					type: "ComponentContainer",
					title: "Intro Form",
					key: "landingForm",
					whiteList: ["BasicIntroForm"],
				},
				{
					type: "TextField",
					title: "Brochure Title",
					placeholder: "Type brochure title",
					key: "brochureTitle",
					hideable: true,
				},
				{
					type: "TextField",
					title: "Brochure URL",
					key: "brochureUrl",
					placeholder: "Type brochure URL",
					validators: {
						format: "URL",
					},
				},
				{
					type: "TextField",
					title: "Brochure CTA Label",
					key: "brochureCtaLabel",
					placeholder: "Type CTA label",
				},
			],
		},
	],

	default: {
		component: "LandingProgramNavigation",
		programTabs: [
			{
				component: "LandingProgramNavigationTab",
				title: "",
				elements: [],
			},
		],
		landingForm: {
			component: "BasicIntroForm",
		},
		brochureTitle: "",
		brochureUrl: "",
		brochureCtaLabel: "Download brochure",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/ProgramNavigation/thumbnail@1x.png",
		"2x": "/thumbnails/modules/ProgramNavigation/thumbnail@2x.png",
	},
};

export default schema;
