import { ImageBannerProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	heading,
	image,
	link,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<ImageBannerProps> = {
	schemaType: "module",
	component: "ImageBanner",
	displayName: "Image banner",
	category: "content",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					...image,
					helptext: "Recommended minimum image size: 792x514",
					mandatory: true,
				},
				{ ...heading },
				{
					type: "RichText",
					title: "Content",
					mandatory: true,
					isMockup: true,
					key: "content",
				},
				{ ...link, hideable: true },
			],
		},

		{
			title: "config",
			fields: [{ ...anchorID }, { ...verticalSpacing }],
		},
	],

	default: {
		component: "ImageBanner",
		image: {
			component: "Image",
			decoding: "sync",
			fetchpriority: "high",
			loading: "eager",
		},
		link: {
			component: "Button",
			url: {
				title: "Link de prueba",
				linkToURL: "http://www.google.com",
				newTab: false,
				noFollow: false,
			},
		},
		subtheme: "default",
		veilOpacity: 0.33,
		title: { content: "Lorem ipsum", tag: "h2" },
		content: "Lorem ipsum paragraph",
		anchorID: null,
		verticalSpacing: "medium",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/ImageBanner/thumbnail@1x.png",
	},
};

export default schema;
