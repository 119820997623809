import { HighlightCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { image, link } from "@schemas/presets";

const schema: Schema.Component<HighlightCardProps> = {
	schemaType: "component",
	component: "HighlightCard",
	displayName: "Highlight Card",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...image },
				{
					type: "TextField",
					title: "Title",
					key: "title",
					hideable: true,
				},
				{
					type: "TextField",
					title: "Subtitle",
					key: "subtitle",
					hideable: true,
				},
				{
					...link,
					title:
						"Link to page                                                                                                                                                                                                                      ",
				},
			],
		},
	],

	default: {
		component: "HighlightCard",
		professorName: "",
		titleProfessor: "",
		link: { component: "Button" },
		image: { component: "Image" },
	},

	thumbnails: {
		"1x": "/thumbnails/components/HighlightCard/thumbnail@1x.png",
		"2x": "/thumbnails/components/HighlightCard/thumbnail@2x.png",
	},
};

export default schema;
