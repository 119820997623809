const isValidURL = (uri: string) => {
	try {
		return Boolean(new URL(uri));
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
	} catch (e) {
		return false;
	}
};

export default isValidURL;
