import { HighlightCarouselProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { anchorID, heading } from "@schemas/presets";

const schema: Schema.Module<HighlightCarouselProps> = {
	schemaType: "module",
	component: "HighlightCarousel",
	category: "collection",
	displayName: "HighlightCarousel",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading },
				{
					type: "TextField",
					key: "subtitle",
					title: "Subtitle",
					hideable: true,
				},
				{
					type: "ComponentArray",
					key: "highlightCards",
					title: "Highlight Cards",
					contentType: "components",
					whiteList: ["HighlightCard"],
				},
			],
		},

		{
			title: "config",
			fields: [{ ...anchorID }],
		},
	],

	default: {
		component: "HighlightCarousel",
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		verticalSpacing: "medium",
		subtitle: "Lorem ipsum paragraph",
		intro: "Lorem ipsum paragraph",
		highlightCards: [],
	},

	thumbnails: {
		"1x": "/thumbnails/modules/HighlightCarousel/thumbnail@1x.png",
		"2x": "/thumbnails/modules/HighlightCarousel/thumbnail@1x.png",
	},
};

export default schema;
